import styled from '@emotion/styled'
import { AgreeCheckboxProps } from '../../types/index'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { functionalColor } from '@/utils/themeConfigs/customTheme'

const CenterFormGroup = styled(FormGroup)`
  margin: 0 auto;
`
const CustomControlLabel = styled(FormControlLabel)`
  margin-right: 0;
`

const GrayCheckBox = styled(Checkbox)`
  color: ${functionalColor.lightGray};
`

export const AgreeCheckbox = ({ isAgree, handleChange }: AgreeCheckboxProps) => {
  return (
    <CenterFormGroup>
      <CustomControlLabel
        control={<GrayCheckBox value={isAgree} onChange={handleChange} />}
        label='注意事項に同意する'
      />
    </CenterFormGroup>
  )
}
