import { useState } from 'react'
import { useRouter } from 'next/navigation'
import styled from '@emotion/styled'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { Box } from '@mui/material'

import Image from 'next/image'
import { useSetAuthContext } from '@/context/AuthContext'
import { useAuthentication } from '@/utils/hooks/useAuthentication'
import { auth } from '@/utils/libs/firebase/client'
import { cookies } from '@/utils/helpers/cookie'

import { LoginContent } from '../uis/LoginContent'

const Container = styled(Box)`
  padding: 172px 0;
  ${mediaQuery('tab')} {
    padding: 72px 0;
  }
`

const Content = styled(Box)`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
`
const BadgeImage = styled(Image)`
  width: 90%;
  max-width: 400px;
  height: auto;
  ${mediaQuery('tab')} {
    max-width: 343px;
    width: 76%;
  }
`

export const LoginPage = () => {
  const [isAgree, setIsAgree] = useState<boolean>(false)
  const [isValid, setIsValid] = useState(true)
  const { login } = useAuthentication()
  const { setUser, setIsStudent, setIsAdmin } = useSetAuthContext()
  const { setCookie } = cookies()
  const router = useRouter()

  const handleAgreeChange = () => {
    setIsAgree((prev) => !prev)
  }

  const handleLogin = async () => {
    if (!isAgree) {
      setIsValid(false)
      return
    }

    const { response, token, refreshToken } = await login()

    if (response) {
      const { role_id, cloudfront_cookies } = response
      const date = new Date()
      // 念のため、24h後を設定
      const expires = new Date(date.setDate(date.getDate() + 1))
      // cookieにCloudFrontの情報を保持
      setCookie('CloudFront-Policy', cloudfront_cookies['CloudFront-Policy'], expires)
      setCookie(
        'CloudFront-Signature',
        cloudfront_cookies['CloudFront-Signature'],
        expires,
      )
      setCookie(
        'CloudFront-Key-Pair-Id',
        cloudfront_cookies['CloudFront-Key-Pair-Id'],
        expires,
      )
      // User情報
      setUser({
        token: token,
        refreshToken: refreshToken,
        id: '',
        roleId: role_id,
        email: '',
        infoFlag: false,
      })
      setIsStudent(role_id === 1)
      setIsAdmin(role_id === 3)
      router.push('/')
    } else {
      // loginAPIのリクエスト失敗
      // logoutではないのでFirebaseのsignOutのみを実行する
      await auth.signOut()
    }
  }

  return (
    <Container>
      <Content>
        <BadgeImage src='/login-logo.png' alt='otomotto' width={400} height={105} />
        <LoginContent
          isAgree={isAgree}
          isValid={isValid}
          handleChange={handleAgreeChange}
          login={handleLogin}
        />
      </Content>
    </Container>
  )
}
