import styled from '@emotion/styled'
import { MicrosoftLogo } from '@/assets/image/microsoft-logo'
import { MicrosoftLoginButtonProps } from '../../types'
import { Button } from '@mui/material'
import { bgColor } from '@/utils/themeConfigs/customTheme'

const LoginButton = styled(Button)`
  width: 363px;
  hight: 83px;
  padding: 0;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow:
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.02),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.02),
    0px 6px 6px -3px rgba(0, 0, 0, 0.02),
    0px 12px 12px -6px rgba(0, 0, 0, 0.02),
    0px 24px 24px -12px rgba(0, 0, 0, 0.02);
  background: ${bgColor.white};
  &:hover {
    background: ${bgColor.white};
  }
`

export const MicrosoftLoginButton = ({ login }: MicrosoftLoginButtonProps) => {
  return (
    <LoginButton
      variant='contained'
      onClick={() => {
        void (async () => {
          await login()
        })()
      }}
    >
      <MicrosoftLogo />
    </LoginButton>
  )
}
