import { AgreeCheckbox } from './AgreeCheckbox'
import { LoginExplanation } from './LoginExplanation'
import { MicrosoftLoginButton } from './LoginButton'
import { LoginContentProps } from '../../types/index'
import { Box, styled, Typography, List, ListItem, ListItemText } from '@mui/material'
import { mediaQuery } from '@/utils/helpers/breakpoint'

import {
  bgColor,
  functionalColor,
  mainColor,
  textColor,
} from '@/utils/themeConfigs/customTheme'

const Content = styled(Box)`
  width: 94%;
  max-width: 710px;
  background: ${bgColor.lightestGray};
  padding: 38px;
  border-radius: 12px;
  box-shadow:
    0px 24px 24px -12px rgba(0, 0, 0, 0.02),
    0px 12px 12px -6px rgba(0, 0, 0, 0.02),
    0px 6px 6px -3px rgba(0, 0, 0, 0.02),
    0px 3px 3px -1.5px rgba(0, 0, 0, 0.02),
    0px 1px 1px -0.5px rgba(0, 0, 0, 0.02);
`

const Inner = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 17px;
  text-align: center;
`

const Warning = styled(Box)`
  color: ${functionalColor.red};
`
const NoteBox = styled(Box)`
  border: solid 1px ${mainColor.user};
  background-color: ${bgColor.white};
`
const NoteTitle = styled(Typography)`
  font-size: 16px;
  padding: 8px 0;
  font-weight: 700;
  background-color: ${mainColor.user};
  color: ${textColor.white};
`
const NoteList = styled(List)`
  padding: 20px 40px;
  ${mediaQuery('tab')} {
    padding: 16px;
  }
`
const NoteItem = styled(ListItem)`
  position: relative;
  padding: 0;
`

const NoteText = styled(ListItemText)`
  & .MuiTypography-root {
    font-size: 14px;
    text-indent: -1em;
    padding-left: 1em;
  }
`

export const LoginContent = ({
  isAgree,
  isValid,
  handleChange,
  login,
}: LoginContentProps) => {
  return (
    <Content>
      <Inner>
        {!isValid && <Warning>生成AI利用上の注意事項に同意して下さい</Warning>}
        <LoginExplanation />
        <MicrosoftLoginButton login={login} />
        <AgreeCheckbox isAgree={isAgree} handleChange={handleChange} />
        <NoteBox>
          <NoteTitle variant='h2'>生成AI利用上の注意事項</NoteTitle>

          <NoteList>
            <NoteItem>
              <NoteText primary='・学習及び学校活動の範囲でのみ利用する' />
            </NoteItem>
            <NoteItem>
              <NoteText primary='・生成AIの特性を理解して適切に利用する' />
            </NoteItem>
            <NoteItem>
              <NoteText primary='・出力結果を利用する際は内容の正確性等について自己の責任で確認する' />
            </NoteItem>
            <NoteItem>
              <NoteText primary='・出力結果を転載する際はAIが生成したことを明示し、自身が作成したものと偽らない' />
            </NoteItem>
            <NoteItem>
              <NoteText primary='・個人情報やその他非公開の情報を入力しない' />
            </NoteItem>
            <NoteItem>
              <NoteText primary='・本システムは安全な運用と今後の機能開発等のため、利用内容を記録し確認する' />
            </NoteItem>
            <NoteItem>
              <NoteText primary='・ログを分析して個人を特定できない形で公開することがある' />
            </NoteItem>
            <NoteItem>
              <NoteText primary='・不適切な利用があるときは該当アカウントを停止し、指導の対象とする' />
            </NoteItem>
          </NoteList>
        </NoteBox>
      </Inner>
    </Content>
  )
}
